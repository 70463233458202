import React from 'react';
import './snippets.css';

function Policy() {
  return (
    <div className="policy-container">
      <h1>개인정보처리방침</h1>
      <p><strong>[몽글몽글]</strong>('https://mongles.com'이하 '몽글몽글')은(는) 정보주체의 개인정보 보호를 매우 중요시하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 준수하고 있습니다. 본 개인정보처리방침은 본 웹사이트가 제공하는 서비스의 이용과 관련하여 수집·이용되는 개인정보의 항목, 개인정보의 처리 목적, 개인정보의 처리 및 보관 기간, 개인정보의 제3자 제공 등을 설명합니다.</p>
      <h2>1. 개인정보의 수집 및 이용 목적</h2>
      <p>본 웹사이트는 다음의 목적을 위해 개인정보를 수집하고 있습니다.</p>
      <ul>
        <li>서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산</li>
        <li>회원 관리</li>
        <ul>
          <li>회원제 서비스 이용에 따른 본인확인</li>
          <li>개인식별, 부정 이용 방지와 비인가 사용 방지</li>
          <li>가입 의사 확인, 가입 및 가입 횟수 제한</li>
          <li>고객 문의 응대</li>
        </ul>
      </ul>
      <h2>2. 수집하는 개인정보의 항목</h2>
      <p>본 웹사이트에서는 회원가입 및 서비스 이용과정에서 아래와 같은 개인정보를 수집하고 있습니다.</p>
      <ul>
        <li>필수항목: 대학교, 대학교 이메일, 접속 로그</li>
        <li>서비스 이용과정이나 사업 처리 과정에서 해당 정보들이 자동으로 생성되어 수집될 수 있습니다.</li>
      </ul>
      <h2>3. 개인정보의 처리 및 보관 기간</h2>
      <p>귀하의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 다만, 다른 법령에 의해 보존의무가 부과되었을 경우에는 그에 따라 보관합니다.</p>
      <h2>4. 개인정보의 제3자 제공</h2>
      <p>본 웹사이트는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우는 예외로 합니다.</p>
      <ul>
        <li>법률에 특별한 규정이 있는 경우</li>
        <li>서비스 제공에 따른 요금정산을 위해 필요한 경우</li>
        <li>통계작성, 학술연구 또는 시장조사를 위해 필요한 경우, 단 이 경우에는 특정 개인을 식별할 수 없는 형태로 정보를 제공합니다.</li>
      </ul>
      <h2>5. 정보주체의 권리·의무 및 그 행사방법</h2>
      <p>이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있습니다. 또한 서비스 이용을 위해 등록한 정보의 삭제를 요청할 수 있습니다.</p>
      <h2>6. 개인정보 보호책임자</h2>
      <ul>
        <li>이름: 곽병혁</li>
        <li>직책: 운영 및 개발자</li>
        <li>연락처: 01090044993, dev.mongle@gmail.com</li>
      </ul>
    </div>
  );
}

export default Policy;
